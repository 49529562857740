<template>
  <v-breadcrumbs :items="breadcrumbItems" v-if="!hideBreadcrumbs">
    <template v-slot:divider>
      <div class="divider-img">
        <v-img src="../../../assets/Images/breadcrumb-right-con.png" width="6" height="11"></v-img>
      </div>
    </template>
    <template v-slot:prepend>
      <div class="home-img">
        <v-img src="../../../assets/Images/House.png" class="cursor-pointer" width="20" height="20"
          @click="goToAssets"></v-img>
      </div>
      <div class="divider-img" v-if="isAssetsRoute">
        <v-img src="../../../assets/Images/breadcrumb-right-con.png" width="6" height="11"></v-img>
      </div>
    </template>
    <template v-slot:item="{ item, index }">
      <div class="text-capitalize test-text d-flex align-end"
        v-if="index === breadcrumbItems.length - 1 && !showTestsDropdown && item.title === 'Tests'">
        {{ item.title }}
        <v-icon width="16" height="16" @click="toggleTestsDropdown">mdi-chevron-down</v-icon>
      </div>
      <div class="text-capitalize test-text" v-else-if="showTestsDropdown && item.title === 'Tests'" ref="dropdownMenu">
        {{ item.title }}
        <v-icon width="16" height="16" @click="toggleTestsDropdown">mdi-chevron-up</v-icon>
        <div class="dropdown-menu" v-if="allTests.length > 0">
          <div class="test-name-div" v-for="(test, index) in allTests" :key="index">
            <div class="breadcrumb-test-name" @click="openTestPage(test, index)"
              :class="{ 'active': activeTestIndex === index }">
              {{ getTestDisplayName(test.testType, test.index) }}
              <span v-if="test.testTemperature && test.testTemperature_UOM">
                ({{ test.testTemperature }} {{ test.testTemperature_UOM }})
              </span>
            </div>
          </div>
        </div>
      </div>
      <router-link class="text-capitalize" @click.native="handleBreadcrumb(item.title)" v-else
        :to="{ path: item.href }">
        {{ item.title }}
      </router-link>
    </template>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
  </v-breadcrumbs>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Links",
  data: () => ({
    items: [],
    currentURLSegments: [],
    showTestsDropdown: false,
    tenantId: localStorage.getItem("tenantId"),
    allTests: [],
    snackbar: {
      visible: false,
      message: "",
      color: "success",
      timeout: 3000,
    },
  }),
  computed: {
    ...mapGetters("test", ['activeTestIndex']),
    isAssetsRoute() {
      return this.$route.name !== 'Assets' && this.$route.name !== 'DataManagement' && this.$route.name !== 'FluidsDataStudio' && this.$route.name !== 'Main';
    },
    hideBreadcrumbs() {
      const pathSegments = this.$route.path.split('/').filter(Boolean); // Remove empty segments
      if (pathSegments.length < 2) return false; // Ensure there's a valid path after the tenant ID

      const routePath = `/${pathSegments.slice(1).join('/')}`.toLowerCase(); // Remove tenant ID and reconstruct path

      return routePath.includes('/dashboards') ||
        routePath.includes('/fluidsdata-studio') ||
        routePath.includes('/exploratory-data-analysis');
    },

    breadcrumbItems() {
      const itemsCopy = this.items.slice();
      const pathSegments = this.$route.path.split('/').filter(Boolean); // Remove empty segments
      if (pathSegments.length < 2) return []; // Ensure there's a valid path after the tenant ID

      const tenantId = pathSegments[0]; // First segment is always the tenant ID
      const filteredSegments = pathSegments.slice(1); // Remove tenant ID from segments

      const assetParam = this.$route.params.asset;
      const fieldParam = this.$route.params.field;
      const wellParam = this.$route.params.well;
      const currentRoute = `/${filteredSegments.join('/')}`; // Rebuild path without tenant ID

      if (
        currentRoute.includes("/composition") ||
        currentRoute.includes("/dltest") ||
        currentRoute.includes("/ccetest") ||
        currentRoute.includes("/cvdtest") ||
        currentRoute.includes("/separatortest") ||
        currentRoute.includes("/viscositytest")
      ) {
        itemsCopy.push(
          {
            title: assetParam ? `Asset: ${assetParam.replace(/\//g, '-').replace(/ /g, '-')}` : "Assets",
            href: `/${tenantId}/assets/${assetParam || ''}`
          },
          {
            title: fieldParam ? `Field: ${fieldParam.replace(/\//g, '-').replace(/ /g, '-')}` : "Fields",
            href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}`
          },
          {
            title: wellParam ? `Well: ${wellParam.replace(/\//g, '-').replace(/ /g, '-')}` : "Wells",
            href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}/${wellParam || ''}`
          },
          {
            title: "Samples",
            href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}/${wellParam || ''}`
          },
          {
            title: "Tests",
            href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}/${wellParam || ''}`
          }
        );
      } else if (wellParam) {
  itemsCopy.push(
    { title: `Asset: ${assetParam || "Assets"}`, href: `/${tenantId}/assets/${assetParam || ''}` },
    { title: `Field: ${fieldParam}`, href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}` },
    { title: `Well: ${wellParam}`, href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}/${wellParam || ''}` }
  );
} else if (fieldParam) {
  itemsCopy.push(
    { title: `Asset: ${assetParam || "Assets"}`, href: `/${tenantId}/assets/${assetParam || ''}` },
    { title: `Field: ${fieldParam}`, href: `/${tenantId}/assets/${assetParam || ''}/${fieldParam || ''}` }
  );
} else if (assetParam) {
  itemsCopy.push(
    { title: `Asset: ${assetParam || "Assets"}`, href: `/${tenantId}/assets/${assetParam || ''}` }
  );
} else {
  let currentPath = `/${tenantId}`;
  itemsCopy.push(
    ...filteredSegments
      .filter(segment => !["assets", "data-management", "dashboards", "fluidsdata-studio"].includes(segment.toLowerCase())) // Remove unnecessary segments
      .map(segment => {
        currentPath += `/${segment.replace(/\//g, '-').replace(/ /g, '-')}`;
        let title = segment.charAt(0).toUpperCase() + segment.slice(1);
        return {
          title,
          href: currentPath,
          icon: "mdi-chevron-down"
        };
      })
  );
}
      return itemsCopy;
    },
    displayTestType() {
      return function (testType) {
        switch (testType) {
          case 'composition':
            return 'Fluid Composition';
          case 'dltest':
            return 'DL Test';
          case 'ccetest':
            return 'CCE Test';
          case 'cvdtest':
            return 'CVD Test';
          case 'separatortest':
            return 'Separator Test';
          case 'viscositytest':
            return 'Viscosity Test';
          default:
            return testType;
        }
      }
    },
  },
  methods: {
    ...mapActions("test", ['setActiveTestIndex']),
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },
    goToAssets() {
      const lastView = localStorage.getItem("activeMenu"); // Get 'lastView' from localStorage
      const urlSegments = window.location.pathname.split("/").filter(Boolean); // Get URL segments
      const tenantId = urlSegments.length > 0 ? urlSegments[0] : localStorage.getItem("tenantId"); // Extract tenant ID from URL or fallback to localStorage

      if (!tenantId) {
        console.error("Tenant ID is missing!");
        return;
      }

      // Check if lastView is 'data management'
      if (lastView && lastView.toLowerCase() === "data management") {
        this.$router.push(`/${tenantId}/data-management`); // Redirect to /{tenantId}/data-management
      }
      // Check if the first segment of the URL is 'assets'
      else if (urlSegments[1] && urlSegments[1].toLowerCase() === "assets") {
        this.$router.push(`/${tenantId}/assets`); // Redirect to /{tenantId}/assets
      }
      // Add additional redirection logic here if needed
    },
    updateBreadcrumbs() {
      const currentURL = window.location.pathname;
      this.currentURLSegments = currentURL
        .split("/")
        .filter((segment) => segment !== "");
      let updatedPath = `/${this.currentURLSegments.join("/")}`;
      updatedPath = updatedPath.replace(/\/$/, "");
      window.history.replaceState({}, "", updatedPath);
    },
    toggleTestsDropdown(event) {
      event.stopPropagation();
      this.showTestsDropdown = !this.showTestsDropdown;
    },
    showTests() {
      this.allTests = [];
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/tests`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": this.tenantId,
          },
        })
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const tests = response.data.data.allTests;

            const testGroups = {};
            tests.forEach(test => {
              if (!testGroups[test.testType]) {
                testGroups[test.testType] = [];
              }
              testGroups[test.testType].push(test);
            });

            Object.keys(testGroups).forEach(testType => {
              const group = testGroups[testType];
              if (group.length > 1) {
                group.forEach((test, index) => {
                  test.index = index + 1;  // Add numbering (1-based index)
                });
              }
            });

            this.allTests = [].concat(...Object.values(testGroups));
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("Error in fetching tests", "error");
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        });
    },

    getTestDisplayName(testType, index) {
      const testTypeDisplay = this.displayTestType(testType);
      return index ? `${testTypeDisplay} (Test #${index})` : testTypeDisplay;
    },

    openTestPage(test, index) {
      this.setActiveTestIndex(index);
      this.showTestsDropdown = false;

      const sampleId = localStorage.getItem("sampleId");
      const testId = test.ID;
      localStorage.setItem("sampleId", sampleId);
      localStorage.setItem("testId", testId);
      const testType = test.testType;

      // Extract tenant ID from the URL
      const pathSegments = this.$route.path.split('/').filter(Boolean);
      const tenantId = pathSegments[0] || ''; // First segment is always tenant ID

      const routeParams = { ...this.$route.params };
      let newRoute = `/${tenantId}/assets/${routeParams.asset ? routeParams.asset.replace(/\//g, '-').replace(/ /g, '-') : ''}/${routeParams.field ? routeParams.field.replace(/\//g, '-').replace(/ /g, '-') : ''}/${routeParams.well ? routeParams.well.replace(/\//g, '-').replace(/ /g, '-') : ''}/${testType ? testType.replace(/\//g, '-').replace(/ /g, '-') : ''}/${testId ? testId.replace(/\//g, '-').replace(/ /g, '-') : ''}`;

      localStorage.setItem("lastView", newRoute);
      this.$router.push(newRoute);
    },
    closeDropdownMenuOnClickOutside(event) {
      if (this.showTestsDropdown) {
        if (this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target)) {
          this.showTestsDropdown = false;
        }
      }
    },
    handleBreadcrumb(title) {
      if (title === "Dashboards") {
        localStorage.removeItem("lastVisitedDashboard")
      }
    }
  },
  watch: {
    $route(to, from) {
      this.updateBreadcrumbs();
    },
    breadcrumbItems: {
      handler(newItems, oldItems) {
        const containsTests = newItems.some(item => item.title === 'Tests');
        if (containsTests) {
          this.showTests();
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    document.body.addEventListener("click", this.closeDropdownMenuOnClickOutside);
    this.updateBreadcrumbs();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.closeDropdownMenuOnClickOutside);
  },
};
</script>