import axios from 'axios';
import router from "@/router";
const state = {
    tenantData: [],
    selectedTenant: null,
    tenantError: null,
    user: null,
};

const mutations = {
    SET_TENANT_DATA(state, data) {
        state.tenantData = data;
    },
    SET_SELECTED_TENANT(state, { name, ID }) {
        state.selectedTenant = { name, ID };
    },
    SET_TENANT_ERROR(state, error) {
        state.tenantError = error;
    },
    CLEAR_TENANT_ERROR(state) {
        state.tenantError = null;
    },
    SET_USER(state, user) {
        state.user = user;
    },
};

const actions = {
    getUserData({ commit }) {
        const extractTenantId = () => {
            const match = window.location.pathname.split("/").filter(Boolean);
            return match[0] || null; // Returns the first part of the path as tenantId
        };
        const tenantId = extractTenantId();
        return axios.get(process.env.VUE_APP_API_URL + "public/api/admin/user-info", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "X-TenantID": tenantId || "" // Ensure it's always set, even if null
            }
        })
        .then(response => {
            if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
                commit("SET_USER", response.data.data.user);
            } else if (response && response.data && response.data.statusCode === 401) {
                localStorage.removeItem("authToken");
                localStorage.removeItem("tenantId");
                localStorage.removeItem("tenantName");
                localStorage.removeItem("savedChartData");
                localStorage.removeItem("defaultFilter");
                router.push("/login");
            } else if (response && response.data && response.data.message) {
                console.error(response.data.message);
            } else {
                console.log("Unexpected error found");
            }
        })
        .catch(error => {
            if (error.code === "ERR_NETWORK") {
                localStorage.removeItem("authToken");
                localStorage.removeItem("tenantId");
                localStorage.removeItem("tenantName");
                localStorage.removeItem("savedChartData");
                localStorage.removeItem("lastFilterId");
                router.push("/login");
            }
            if (error.response) {
                console.error("Error initializing store:", error);
            }
        });
    },
    async fetchTenants({ commit, dispatch }) {
        commit("CLEAR_TENANT_ERROR");
        try {
            const response = await axios.get(
                process.env.VUE_APP_API_URL + "public/api/admin/tenants",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                    timeout: 15000

                }
            );

            if (response.data.statusCode === 200) {
                commit("SET_TENANT_DATA", response.data.data.tenants);
                //dispatch("getUserData")
            }
        } catch (error) {
            if (error.code === "ECONNABORTED") {
                const tenantTimeoutMessage = `Request timeout. Try again`;
                commit("SET_TENANT_ERROR", tenantTimeoutMessage)
            }
        }
    },
};

const getters = {
    getTenantData: (state) => state.tenantData,
    getSelectedTenant: (state) => state.selectedTenant,
    getTenantError: state => state.tenantError,
    getUser: state => state.user,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
