import { createWebHistory, createRouter } from "vue-router";
import DifferentialLiberationSection from "../views/DifferentialLiberationSection.vue";

import FluidsDataStudioTwo from "../views/FluidsDataStudioTwo.vue";
import ConstantCompositionExpansion from "../views/ConstantCompositionExpansion";
import MultiStageSeparatorTest from "../views/MultiStageSeparatorTest";
import CvdTest from "../views/CvdTest";
import OilViscosity from "../views/OilViscosity";
import Composition from "../views/Composition";
import store from "@/store";
// AI / ML Modelling
import AIMLModelling from "../views/AIMLModelling/AIMLModelling";
import DataInputs from "../views/AIMLModelling/DataInputs";
import UploadReports from "../views/AIMLModelling/UploadReports";
import SelectData from "../views/AIMLModelling/SelectData";
import DataPreview from "../views/AIMLModelling/DataPreview";
import AIMLModels from "../views/AIMLModelling/AIMLModels";
import SupervisedMLModels from "../views/AIMLModelling/SupervisedMLModels";
import FluidsdataAIModel from "../views/AIMLModelling/FluidsdataAIModel";
import BlackOilTable from "../views/AIMLModelling/BlackOilTable";
import SelectedPVTReport from "../views/AIMLModelling/SelectedPVTReport";
import FullPVTReport from "../views/AIMLModelling/FullPVTReport";
import ManualDataEntry from "../views/AIMLModelling/ManualDataEntry";

// SingleSampleFlow
import Assets from "../views/SingleSampleFlow/Assets";
import Fields from "../views/SingleSampleFlow/Fields";
import Wells from "../views/SingleSampleFlow/Wells";
import Samples from "../views/SingleSampleFlow/Samples";
import OilTest from "../views/SingleSampleFlow/OilTest";
import GasTest from "../views/SingleSampleFlow/GasTest";

// Login
import Login from "../views/Auth/Login"

// Exploratory Data Analysis
import DataInventoryAnalysis from "../views/ExploratoryDataAnalysis/DataInventoryAnalysis";
import DataExploration from "../views/DataExploration";
import CompositionChart from "../views/ExploratoryDataAnalysis/CompositionChart";
import CorrelationAnalysis from "../views/ExploratoryDataAnalysis/CorrelationAnalysis";
import CorrelationCoefficient from "../views/ExploratoryDataAnalysis/CorrelationCoefficient";
import FluidsdataInsight from "../views/ExploratoryDataAnalysis/FluidsdataInsight";
import EDADashboard from "../views/ExploratoryDataAnalysis/EDADashboard.vue";
import axios from "axios";
// Dashboard
import Main from "../views/Dashboard/Main"
import Board from "../views/Dashboard/Board"

// Dashboard Update
import DashboardList from "../views/DashboardNew/List"
import DashboardView from "../views/DashboardNew/View"
import DashboardCreate from "../views/DashboardNew/Create"
import DashboardEdit from "../views/DashboardNew/Edit"
import { getTenantPrefix } from "../utils/tenant";
const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    if (to.path !== "/login") return next("/login"); // Redirect only if not already on /login
  } else {
    const tenantPrefix = getTenantPrefix();
    if (!tenantPrefix) return next("/login"); // Redirect only when tenant is missing
    return next(`/${tenantPrefix}/data-management`); // Redirect logged-in users with tenant
  }
  return next();
};
const auth = (to, from, next) => {
  const authToken = localStorage.getItem("authToken");

  if (!authToken) {
    if (to.path !== "/login") return next("/login"); // Avoids redirect loop
    return next();
  }

  const tenantPrefix = getTenantPrefix();
  if (!tenantPrefix) {
    localStorage.removeItem("authToken"); // Clear token if tenant is missing
    if (to.path !== "/login") return next("/login");
  }

  return next(); // Proceed if everything is valid
};

const routes = [
  {
    path: "/",
    beforeEnter: guest,
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: Login
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?/dltest/:id`,
    name: "DifferentialLiberationSection",
    beforeEnter: auth,
    component: DifferentialLiberationSection,
  },
  {
    path: `/:${getTenantPrefix()}/Fluidsdata-studio`,
    name: "FluidsDataStudioTwo",
    beforeEnter: auth,
    component: FluidsDataStudioTwo,
  },

  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?/ccetest/:id`,
    name: "ConstantCompositionExpansion",
    beforeEnter: auth,
    component: ConstantCompositionExpansion,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?/separatortest/:id`,
    name: "MultiStageSeparatorTest",
    beforeEnter: auth,
    component: MultiStageSeparatorTest,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?/viscositytest/:id`,
    name: "OilViscosity",
    beforeEnter: auth,
    component: OilViscosity,
  },
  {
    path: `/:${getTenantPrefix()}/assets`,
    name: "Assets",
    beforeEnter: auth,
    component: Assets,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset`,
    name: "Fields",
    beforeEnter: auth,
    component: Fields,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field`,
    name: "Wells",
    beforeEnter: auth,
    component: Wells,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?`,
    name: "Samples",
    beforeEnter: auth,
    component: Samples,
  },
  {
    path: `/:${getTenantPrefix()}/oilTest`,
    name: "OilTest",
    beforeEnter: auth,
    component: OilTest,
  },
  {
    path: `/:${getTenantPrefix()}/gasTest`,
    name: "GasTest",
    beforeEnter: auth,
    component: GasTest,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?/composition/:id`,
    name: "Composition",
    beforeEnter: auth,
    component: Composition,
  },
  {
    path: `/:${getTenantPrefix()}/assets/:asset/:field/:well/:sampleId?/cvdtest/:id`,
    name: "CvdTest",
    beforeEnter: auth,
    component: CvdTest,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling`,
    name: "AIMLModelling",
    beforeEnter: auth,
    component: AIMLModelling,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs`,
    name: "DataInputs",
    beforeEnter: auth,
    component: DataInputs,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports`,
    name: "UploadReports",
    beforeEnter: auth,
    component: UploadReports,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/select-from-database`,
    name: "SelectData",
    beforeEnter: auth,
    component: SelectData,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/data-preview`,
    name: "DataPreview",
    beforeEnter: auth,
    component: DataPreview,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/data-preview/ai-ml-models`,
    name: "AIMLModels",
    beforeEnter: auth,
    component: AIMLModels,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression`,
    name: "SupervisedMLModels",
    beforeEnter: auth,
    component: SupervisedMLModels,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model`,
    name: "FluidsdataAIModel",
    beforeEnter: auth,
    component: FluidsdataAIModel,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/black-oil-table`,
    name: "BlackOilTable",
    beforeEnter: auth,
    component: BlackOilTable,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/selected-pvt-report`,
    name: "SelectedPVTReport",
    beforeEnter: auth,
    component: SelectedPVTReport,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/full-pvt-report`,
    name: "FullPVTReport",
    beforeEnter: auth,
    component: FullPVTReport,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/black-oil-table`,
    name: "FluidsdataBlackOilTable",
    beforeEnter: auth,
    component: BlackOilTable,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/selected-pvt-report`,
    name: "FluidsdataSelectedPVTReport",
    beforeEnter: auth,
    component: SelectedPVTReport,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/full-pvt-report`,
    name: "FluidsdataFullPVTReport",
    beforeEnter: auth,
    component: FullPVTReport,
  },
  {
    path: `/:${getTenantPrefix()}/ai-ml-modelling/define-inputs/manual-data-entry`,
    name: "ManualDataEntry",
    beforeEnter: auth,
    component: ManualDataEntry,
  },

  {
    path: `/:${getTenantPrefix()}/data-management`,
    name: "DataManagement",
    beforeEnter: auth,
    component: DataExploration,
  },

  // EDA Routes
  {
    path: `/:${getTenantPrefix()}/data-inventory-analysis`,
    name: "DataInventoryAnalysis",
    beforeEnter: auth,
    component: DataInventoryAnalysis,
  },
  {
    path: `/:${getTenantPrefix()}/EDA/data-inventory-analysis/Compositon-chart`,
    name: "CompositionChart",
    beforeEnter: auth,
    component: CompositionChart,
  },
  {
    path: `/:${getTenantPrefix()}/PVT-Properties`,
    name: "CorrelationAnalysis",
    beforeEnter: auth,
    component: CorrelationAnalysis,
  },
  {
    path: `/:${getTenantPrefix()}/EDA/correlation-analysis/PVT-Properties/correlation-coefficient`,
    name: "CorrelationCoefficient",
    beforeEnter: auth,
    component: CorrelationCoefficient,
  },
  {
    path: `/:${getTenantPrefix()}/Fluidsdata-insights`,
    name: "FluidsdataInsight",
    beforeEnter: auth,
    component: FluidsdataInsight,
  },
  {
    path: `/:${getTenantPrefix()}/exploratory-data-analysis`,
    name: "EDADashboard",
    beforeEnter: auth,
    component: EDADashboard,
  },
  {
    path: `/:${getTenantPrefix()}/:catchAll(.*)`,
    name: "NotFound",
    component: () => import("../views/NotFound/NotFound.vue"),
  },

  {
    path: `/:${getTenantPrefix()}/Visualization-&-Dashboards`,
    name: "Main",
    beforeEnter: auth,
    component: Main,
  },
  {
    path: `/:${getTenantPrefix()}/Visualization-&-Dashboards/:name`,
    name: "Board",
    beforeEnter: auth,
    component: Board,
  },
  {
    path: `/:${getTenantPrefix()}/Dashboards`,
    name: "DashboardList",
    beforeEnter: auth,
    component: DashboardList,
  },
  {
    path: `/:${getTenantPrefix()}/Dashboards/view`,
    name: "DashboardView",
    beforeEnter: auth,
    component: DashboardView,
  },
  {
    path: `/:${getTenantPrefix()}/Dashboards/create`,
    name: "DashboardCreate",
    beforeEnter: auth,
    component: DashboardCreate,
  },
  {
    path: `/:${getTenantPrefix()}/Dashboards/edit/:name`,
    name: "DashboardEdit",
    beforeEnter: auth,
    component: DashboardEdit,
  },
  {
    path: `/:${getTenantPrefix()}/:catchAll(.*)`,
    name: "NotFound",
    component: () => import("../views/NotFound/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to save the last visited route
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'NotFound') {
    localStorage.setItem('lastView', to.fullPath);
  }
  if(to.name === "Board") {
    localStorage.setItem("lastVisitedDashboard", to.fullPath)
  }
  next();
});

export default router;